<template>
  <div class="w-full">
    <form
      v-if="showFormBusinessOwner"
      @submit.prevent="businessOwnerFormSubmit"
    >
      <app-alert-error
        v-if="errors['business_id']"
        :message="errors['business_id'][0]"
        class="my-3  bg-red-100 p-3 rounded-md"
      />

      <app-form-input
        id="first_name"
        name="first_name"
        type="text"
        :labelText="$t('business.owner.label_name_of_person')"
        :placeholder="$t('business.owner.first_name')"
        class=""
        v-model="businessOwner.first_name"
        :errorMessage="errors.first_name"
      />

      <app-form-input
        id="last_name"
        name="last_name"
        type="text"
        :placeholder="$t('business.owner.last_name')"
        class="mb-5"
        v-model="businessOwner.last_name"
        :errorMessage="errors.last_name"
      />

      <div class="w-full mb-3 text-sm">
        <label class="inline-flex items-center">
          <input
            type="radio"
            class="form-radio focus:ring-transparent"
            @change="onSelected($event)"
            name="business.owner.identification_type"
            value="MyKad"
            v-model="businessOwner.identification_type"
            checked
          />
          <span class="ml-2">{{ $t("business.representative.mykad") }}</span>
        </label>
        <label class="inline-flex items-center ml-6">
          <input
            type="radio"
            class="form-radio focus:ring-transparent"
            @change="onSelected($event)"
            name="identification_type"
            value="Passport"
            v-model="businessOwner.identification_type"
          />
          <span class="ml-2">{{ $t("business.representative.passport") }}</span>
        </label>
      </div>

      <div class="flex flex-col w-full mb-5">
        <app-form-input
          id="identification"
          name="identification"
          :labelText="labelIdentification()"
          v-model="businessOwner.identification"
          :errorMessage="errors.identification"
          :type="ic_type_input.type"
          :maxlength="ic_type_input.maxlength"
          :numberOnly="ic_type_input.type === 'number'"
        />
      </div>

      <app-form-telephone-no
        name="phone_no"
        :labelText="$t('business.owner.label_phone_no')"
        class="w-full mb-5"
        v-model="businessOwner.phone_no"
        :errorMessage="errors.phone_no"
      />

      <app-form-input
        id="email"
        name="email"
        type="text"
        :labelText="$t('business.owner.label_email')"
        :placeholder="$t('business.owner.email')"
        class=" mt-5"
        v-model="businessOwner.email"
        :errorMessage="errors.email"
      />

      <app-button
        type="submit"
        :loading="loading"
        class="mt-8 "
      >
        {{ $t("general.add") }}
      </app-button>

      <app-button-outline
        type="submit"
        @onClick="showFormAddBusinessOwner(false)"
        class="mt-3 w-full"
      >
        {{ $t("general.back") }}
      </app-button-outline>
    </form>
    <div v-else>
      <div
        class="relative grid grid-cols-2 gap-4 p-5 border my-3  bg-gray-50"
        v-for="owner in business.owners"
        v-bind:key="owner"
      >
        <div>
          <p class="font-semibold">
            {{ $t("business.owner.label_name_of_person") }}
          </p>
          <p class="text-gray-600">
            {{ owner.first_name }}
            {{ owner.last_name }}
          </p>
        </div>

        <div>
          <p class="font-semibold">
            {{ owner.identification_type ?? '-' }}
          </p>
          <p class="text-gray-600">{{ owner.identification ?? "-" }}</p>
        </div>

        <div>
          <p class="font-semibold">
            {{ $t("business.owner.label_phone_no")}}
          </p>
          <p class="text-gray-600">{{ owner.phone_no ?? "-" }}</p>
        </div>

        <div>
          <p class="font-semibold">
            {{ $t("business.owner.label_email") }}
          </p>
          <p class="text-gray-600">
            {{ owner.email }}
          </p>
        </div>

        <app-icon
        v-if="!editMode"
        @click="showModalDeleteShareholder = true"
          name="XIcon"
          class="
            w-5
            mr-3
            top-0
            my-auto
            right-3
            bottom-0
            absolute
            cursor-pointer
          "
        />

        <!--Modal to delete shareholder-->
        <app-modal
          :show="showModalDeleteShareholder"
          @close="showModalDeleteShareholder = false"
          v-if="!editMode"
        >
          <template v-slot:title>{{
            $t("business.modal.delete_business")
          }}</template>
          <template v-slot:body>
            <p class="text-sm text-gray-500">
              {{ $t("business.modal.delete_description") }}
            </p>
          </template>
          <template v-slot:action>
            <app-button
              @click="deleteBusinessOwner(owner?.id)"
              :showf70Icon="false"
              type="submit"
              class="sm:ml-3 sm:w-auto"
              :loading="loading"
              
            >
              {{ $t("business.modal.confirm_delete") }}
            </app-button>
          </template>
        </app-modal>
      </div>


      <app-button
        v-if="!editMode"
        class="relative w-full"
        @onClick="showFormAddBusinessOwner(true)"
        :showf70Icon="false"
        :disabled="editMode"
      >
        <app-icon name="PlusIcon" class="absolute w-5 mr-3 left-3" />
        {{
          business.owners?.length > 0
            ? $t("business.owner.add_another_owner")
            : $t("business.owner.add_business_shareholder")
        }}
      </app-button>

      <app-button-outline class="mt-3 w-full" @onClick="editAction">
        {{ editMode ? $t("general.edit_information") : $t("general.cancel") }}
      </app-button-outline>
      
    </div>
  </div>
</template>

<script>
export default {
  props: ["menu_action", "edit_mode"],

  data() {
    return {
      businessOwner: {},
      ic_type: "MyKad",
      ic_type_input: null,
      showFormBusinessOwner: false,
      showModalDeleteShareholder: false,
      emptyErrors: {
        first_name: [],
        last_name: [],
        email: [],
      },
    };
  },
  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    errors() {
      const storeErrors = this.$store.getters["businessStore/errors"];
      if (this.$lodash.isEmpty(storeErrors)) {
        return this.emptyErrors;
      }
      return storeErrors;
    },
    editMode: {
      get() {
        return this.edit_mode;
      },
      set(v) {
        this.$emit("update:edit_mode", v);
      },
    },
  },
  mounted(){
    this.selectInputType();
  },
  methods: {
    onSelected(event) {
      this.ic_type = event.target.value;
      this.businessOwner.identification = null;
      this.selectInputType();
    },
    selectInputType() {
      switch (this.ic_type) {
        case "Passport":
          this.ic_type_input = {
            type: "text",
            maxlength: 10,
          };
          break;
        case "MyKad":
        default:
          this.ic_type_input = {
            type: "number",
            maxlength: 12,
          };
      }
    },
    labelIdentification() {
      return this.ic_type == "MyKad"
        ? this.$t("business.representative.mykad")
        : this.$t("business.representative.passport");
    },

    async businessOwnerFormSubmit() {
      this.resetState();

      this.businessOwner.business_id = this.business.id;

      const result = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessOwner",
        this.businessOwner
      );

      if (result == null) return;

      if (result != null) {
        this.$store.dispatch("businessStore/getBusinessById", this.business.id);
        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          5000
        );
      }

      this.showFormAddBusinessOwner(false);
    },
    async deleteBusinessOwner(businessOwnerId) {
      this.resetState();

      this.businessOwner.id = businessOwnerId;
      this.businessOwner.business_id = this.business.id;

      const result = await this.$store.dispatch(
        "businessStore/deleteBusinessOwner",
        this.businessOwner
      )
      5000,
        window.location.reload();

      if (result != null) {
        this.isDisableMode = true;
        this.$store.dispatch("businessStore/getBusinessById", this.business.id);
        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          5000
        );
      }
    },
    showFormAddBusinessOwner(value) {
      this.showFormBusinessOwner = value;
      this.businessOwner = {
        identification_type: "MyKad",
      };
      this.ic_type = this.businessOwner.identification_type;
      this.selectInputType();

      this.$store.commit("businessStore/setErrorMessage", {
        errors: this.emptyErrors,
        message: null,
      });
    },
    editAction() {
      this.editMode = !this.editMode;

      if (this.editMode == false) {
        this.$store.dispatch(
          "businessStore/getBusinessById",
          this.$route.params.business_id
        );
      }
    },
    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>
